import React, {memo} from "react";
import Link from "next/link";
import {v5Pages} from "../../_common/_constants";
import Circle from "@components/Nav/Circle";

type RegionItem = {regionName: string; regionSlug: string; locationCount: number};

const RegionLink = ({region: {regionName, regionSlug, locationCount}}: {region: RegionItem}) => (
  <li className="mb-5" data-cy="desktop-menu-item">
    <Link
      href={{
        pathname: v5Pages.clinicDetails,
        query: {slug: regionSlug},
      }}
      className="flex items-center gap-2 min-w-[10rem] group"
    >
      <span className="group-hover:opacity-100 opacity-0">
        <Circle />
      </span>
      <span className="relative">
        {/* Hidden Placeholder to prevent items shift when text get bolder on hover */}
        <span className="whitespace-nowrap font-i font-semibold opacity-0" aria-hidden>
          {regionName}
        </span>
        <span className="whitespace-nowrap font-i hover:font-semibold text-gray-1000 absolute left-0">
          {regionName}
        </span>
      </span>

      <span className="font-m text-xs	text-gray-800">{locationCount}</span>
    </Link>
  </li>
);

export default memo(RegionLink);
