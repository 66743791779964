import {v5Pages} from "../../_common/_constants";
import {useTypedSelector} from "../../../store";
import _ from "lodash";
import {useMemo} from "react";
import {RootStateLocation} from "../../../store/types";

const useDrawerRegions = () => {
  const {locations} = useTypedSelector(({config}) => config);

  // Regions with location count, grouped by state
  const regionGroups = useMemo(() => {
    const groupedByState = _.groupBy(locations, location => {
      return location.address.state === "CA" ? "CA" : "Others";
    });
    return _.mapValues(groupedByState, (locationsInState: RootStateLocation[]) => {
      return _(locationsInState) // chain(locationsInState)
        .groupBy("region.slug")
        .map((groupedLocations, slug) => {
          return {
            title: groupedLocations[0].region.name,
            href: {pathname: v5Pages.clinicDetails, query: {slug}},
            count: groupedLocations.length,
            subSubItems: null,
          };
        })
        .sortBy(r => r.title)
        .value();
    });
  }, [locations]);

  return useMemo(() => {
    const ca = {
      title: "California",
      href: null,
      count: null,
      subSubItems: regionGroups["CA"],
    };
    return _.sortBy([...regionGroups["Others"], ca], r => r.title);
  }, [regionGroups]);
};

export default useDrawerRegions;
